import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function HealthcareIndustry() {
  const renderContent = data => {
    const pageData = {
      industry: "Government",
      header: {
        headerTitle: "Create Safer Communities at Scale with Unified Security ",
        headerParagraph:
          "Improve public safety and protect government buildings with centralized cloud-based security you can access anytime, anywhere. Proactively detect threats and make investigations fast, easy and efficient.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Leaders in Government",
      industryLogos: [
        {
          img: data.logo1,
          width: "90px",
          alt: "Beaver County Tourism",
        },
        {
          img: data.logo2,
          width: "143px",
          alt: "Ventura County Air Pollution",
        },
        {
          img: data.logo3,
          width: "80px",
          alt: "Big Fork Water and Sewer",
        },
        {
          img: data.logo4,
          width: "124px",
          alt: "The Village of Palm Springs Florida",
        },
        {
          img: data.logo5,
          width: "160px",
          alt: "Dawsonville Georgia",
        },
        {
          img: data.logo6,
          width: "157px",
          alt: "City of Centerville",
        },
      ],

      learnRows: [
        {
          image: data.learnImage1,
          title: "Protect Citizens and Property",
          description:
            "Improve public safety across urban and rural communities with cloud-based cameras accessible from anywhere. Cover blind spots and get 24/7/365 visibility on a unified platform with high-resolution video and real-time AI alerts.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title: "Control Entry Points and Secure Building Access",
          description:
            "Ensure only authorized personnel can access restricted areas. Centrally manage unlimited doors, buildings, and credentials from anywhere and pair access events with synchronized video, badge data, and time stamps.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
        {
          image: data.learnImage3,
          title: "Monitor for Persons and Vehicles of Interest",
          description:
            "Detect persons and vehicles of interest around and within government buildings with license plate recognition (LPR) and facial recognition. Set up automatic AI-based alerts to respond to suspicious activity in real time.",
          linkText: "Learn About AI Analytics",
          linkUrl: "/ai-analytics",
        },
        {
          image: data.learnImage4,
          title: "Investigate Quickly and Share Footage with Ease",
          description:
            "Streamline investigations with multi-camera smart search. Swiftly gather video evidence across all cameras and locations, then easily share clips and live camera feeds with local authorities for fast, coordinated response.",
          linkText: "Learn About the Console",
          linkUrl: "/console",
        },
        {
          image: data.learnImage5,
          title: "Protect Privacy and Maintain Compliance",
          description:
            "Preserve citizen privacy and effortlessly maintain critical cybersecurity, data privacy, and compliance standards. Enforce strict privacy protocols and regulations with granular user permissions, privacy regions, end-to-end encryption, and more.",
          linkText: "Learn About Data Security",
          linkUrl: "/security",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Local Government",
          tabTitle: "Local Government",

          description:
            "Protect employees and assets in government buildings. Secure city halls, ballot boxes, courthouses, and more inside and out.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Law Enforcement & Public Safety",
          tabTitle: "Law Enforcement & Public Safety",

          description:
            "Enforce authorized access and provide video monitoring for accountability in police stations, jails, fire departments, and more.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Resource Management",
          tabTitle: "Resource Management",
          description:
            "Protect state and national offices with access control, proactive monitoring, swift threat response, and efficient security operations.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in City Spaces",
          tabTitle: "City Spaces",

          description:
            "Secure dense urban areas with scalable plug-and-play deployments. Cover parks, town squares, and more with one unified system.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "shield",
          title: "Enhance Public Safety",
          p:
            "Capture real-time, high-resolution video with no down time. Receive proactive AI-powered alerts for unwanted behavior, human movement, faces, and more.",
        },
        {
          icon: data.icon2,
          iconAlt: "location",
          title: "Easy to Deploy and Scale",
          p:
            "No DVR/NVRs required. Modern cloud architecture enables simple deployment, limitless scale, and practically zero maintenance.",
        },
        {
          icon: data.icon3,
          iconAlt: "trust",
          title: "Maintain Community Trust",
          p:
            "Rhombus features industry-leading cybersecurity practices, redundant SOC-2 data centers, and fully NDAA compliant hardware.",
        },
        {
          icon: data.icon4,
          iconAlt: "check mark",
          title: "Future-Proof Architecture",
          p:
            "Open API and integrations deliver deep customization and flexibility. Automatic product updates are released monthly at no additional cost.",
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Industry Leaders",
          image: data.qouteImage,
          logo: {
            img: data.caseStudyLogo,
            width: "131px",
            alt: "University Area CDC",
          },
          link: "/case-studies/uacdc",
          author: {
            avatar: data.avatar,
            name: "Rob Zimprich",
            role: "CFO at University Area CDC",
            qoute:
              "“Our IT and security staff absolutely love Rhombus. They tell me that it’s the best system they’ve ever worked with and they’ve been doing this for 20+ years.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Rhombus for Local, State, and Federal Government Facilities
          </title>
          <meta
            name="description"
            content="Discover how Rhombus smart security cameras, sensors, access control, and alarms improve security and visibility for government leaders."
          />
        </Helmet>

        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/government/government-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/government/government-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: {
          eq: "components/industries/img/government/logo1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/government/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/government/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/government/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: {
          eq: "components/industries/img/government/logo5-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/government/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/government/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/government/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/government/facial-recognition-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/government/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/government/user-permissions-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/government/local-government-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/government/law-enforcement-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/government/resource-management-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/government/city-spaces-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/government/mobile-local-government-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/government/mobile-law-enforcement-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/government/mobile-resource-management-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/government/mobile-city-spaces-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/uacdc-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/rob-zimprich-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/uacdc-logo.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
